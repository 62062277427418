import ActionTypes from "./ActionTypes";

export const checkIsLoggedInTrue = () => ({
  type: ActionTypes.checkIsLoggedInTrue,
});

export const checkIsLoggedInFalse = () => ({
  type: ActionTypes.checkIsLoggedInFalse,
});

export const userLoggedInSuccess = (data) => ({
  type: ActionTypes.userLoggedInSuccess,
  payload: data,
});

export const getSiteBasicConfiguration = (data) => ({
  type: ActionTypes.getSiteBasicConfig,
  payload: data,
});

export const getUserProfiles = (data) => ({
  type: ActionTypes.getUserProfiles,
  payload: data,
});

export const removeUserProfile = () => ({
  type: ActionTypes.removeUserProfiles,
});

// export const checkIsLoggedIn = (status) => ({
//     type: ActionTypes.checkIsLoggedIn,
//     payload: status
// });
