import { combineReducers } from "redux";

import UserAuthReducer from "./UserAuth/Reducers";
import GeneralReducer from "./General/Reducers";
import headerReducer from "./HeaderLayout/reducer";
import dashboardReducer from "./Dashboard/reducer";
import playerReducer from "./Player/playerSlice";

const rootReducer = combineReducers({
  UserAuthReducer,
  GeneralReducer,
  headerReducer,
  dashboardReducer,
  playerReducer,
});
export default rootReducer;
