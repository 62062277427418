import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PageRoutes from "./Routes/PageRoutes";
import { ThemeProvider } from "react-bootstrap";
import { getAccessToken } from "./Api/UserAuth";
// import styles
import "./assets/css/reset.css";
import "./assets/fontawesome/css/all.min.css";

import { get_configuration, isLoggedIn, deleteAccessToken } from "./Api/UserAuth";

import {
    checkIsLoggedInFalse,
    checkIsLoggedInTrue,
    getSiteBasicConfiguration,
    userLoggedInSuccess,
} from "./Redux/UserAuth/Actions";
import { changeSiteLanguage } from "./Redux/General/Actions";
import { useDispatch, useSelector } from "react-redux";
import RedLoading from "./Components/General/RedLoading/RedLoading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { detectLangCodeFromURL } from "./helper/helperFunctions";
import i18next from "i18next";

import arabic from "./Translations/ar.json";
import persian from "./Translations/persian.json";
import english from "./Translations/english.json";

const translateFiles = {
    "ar": arabic,
    "en": english,
    "fa": persian
}

const LtrLayout = React.lazy(() => import("./Components/Layouts/LtrLayout"));
const RtlLayout = React.lazy(() => import("./Components/Layouts/RtlLayout"));

function App() {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.GeneralReducer.siteLanguage);
    const userAuth = useSelector((state) => state.UserAuthReducer);

    useEffect(() => {
        let localStorageLanguage = localStorage.getItem("lang");

        const handleChangeLanguage = (langCode, langDir) => {
            dispatch(changeSiteLanguage(langCode, langDir));
            localStorage.setItem("lang", JSON.stringify({ code: langCode, dir: langDir }));
            i18next.changeLanguage(langCode);
        };

        get_configuration()
            .then((res) => {
                if (res.result.forceSubscriptionToSeen) {
                    res.result.forceLoginToSeen = true;
                }

                dispatch(getSiteBasicConfiguration(res.result));

                // Detect Language Part
                const languages = res.result?.languages ?? [];
                let defaultLang = null;
                languages.map((lang) => {
                    if (lang.forWebsite) {
                        let file = translateFiles[lang.code];
                        i18next.addResources(lang.code, "translation", file);

                        if (lang.isDefault) {
                            defaultLang = lang;
                        }
                    }
                    return null;
                });

                // means lang is in the url
                const detectedLang = detectLangCodeFromURL(languages);
                if (detectedLang?.langCode) {
                    handleChangeLanguage(
                        detectedLang?.selectedLanguageInURL?.code,
                        detectedLang?.selectedLanguageInURL?.direction
                    );
                } else {
                    let goToLang = "";
                    if (localStorageLanguage) {
                        const langParsed = JSON.parse(localStorageLanguage);
                        goToLang = langParsed.code;
                        handleChangeLanguage(langParsed.code, langParsed.dir);
                    } 
                    else if (!localStorageLanguage) {
                        goToLang = defaultLang.code;
                        handleChangeLanguage(defaultLang?.code, defaultLang?.direction);
                    }

                    if (!window.location.pathname.includes("oAuth")) {
                        window.location.href = `/${goToLang}${detectedLang.path}`;
                    }
                }
            })
            .catch((err) => err);

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const isAccessTokenExist = Boolean(getAccessToken());

        if (userAuth.isLoggedIn === null) {
            if (userAuth?.siteConfiguration?.forceToLogin || isAccessTokenExist) {
                isLoggedIn()
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(checkIsLoggedInTrue());
                            dispatch(userLoggedInSuccess(res.result));
                        }
                    })
                    .catch((err) => {
                        const { response } = err;
                        if(response.status === 401){
                            deleteAccessToken();
                        }
                        dispatch(checkIsLoggedInFalse());
                    });
            } else {
                dispatch(checkIsLoggedInFalse());
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth.siteConfiguration]);

    if (language.dir === null) {
        return null;
    }

    if (userAuth.isLoggedIn === null || userAuth.siteConfiguration === null) {
        return (
            <div className="initial-loading">
                <RedLoading />
            </div>
        );
    }

    return (
        <ThemeProvider dir={language.dir}>
            <Suspense fallback={<div />}>
                {language.dir === "ltr" ? (
                    <LtrLayout>
                        <Router>
                            <PageRoutes />
                        </Router>
                    </LtrLayout>
                ) : (
                    <RtlLayout>
                        <Router>
                            <PageRoutes />
                        </Router>
                    </RtlLayout>
                )}
            </Suspense>
            <ToastContainer pauseOnFocusLoss position="top-center" theme="dark" autoClose={5000} />
        </ThemeProvider>
    );
}

export default App;
