import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import RoutesHelper from "./RoutesHelper";
import AsyncComponent from "../Components/Helpers/AsyncComponent";

import Header from "../Components/Layouts/Header";
import Footer from "../Components/Layouts/Footer";
import { useSelector } from "react-redux";
import AppBar from "../Components/Layouts/AppBar";
import CommonModals from "../Pages/Common";

// Pages Routes
const Home = AsyncComponent(() => import("../Pages/Home").then((module) => module.default));
const Auth = AsyncComponent(() => import("../Pages/Auth/Auth").then((module) => module.default));
const NewProfile = AsyncComponent(() =>
    import("../Pages/Auth/NewProfile").then((module) => module.default)
);
const DeviceManagement = AsyncComponent(() =>
    import("../Pages/Auth/RemoveDevice").then((module) => module.default)
);
const ProfilePage = AsyncComponent(() =>
    import("../Pages/Dashboard").then((module) => module.default)
);
const SingleMovie = AsyncComponent(() =>
    import("../Pages/ContentDetail").then((module) => module.default)
);
const CastAndCrew = AsyncComponent(() =>
    import("../Pages/CastAndCrew").then((module) => module.default)
);
const Player = AsyncComponent(() =>
    import("../Pages/Player/playerRef").then((module) => module.default)
);
const NotFound = AsyncComponent(() =>
    import("../Pages/NotFound/NotFound").then((module) => module.default)
);
const GeneralPages = AsyncComponent(() =>
    import("../Pages/General").then((module) => module.default)
);
const ActivityPage = AsyncComponent(() =>
    import("../Pages/Dashboard/components/ActivityMobilePage/ActivityMobilePage").then(
        (module) => module.default
    )
);
const SearchPage = AsyncComponent(() =>
    import("../Pages/Search/SearchPage").then((module) => module.default)
);

const CategoryPage = AsyncComponent(() =>
    import("../Pages/CategoryDetail").then((module) => module.default)
);

const SubscriptionPage = AsyncComponent(() =>
    import("../Pages/Subscriptions/SubscriptionPage").then((module) => module.default)
);

const PaymentPage = AsyncComponent(() =>
    import("../Pages/Payment/PaymentPage").then((module) => module.default)
);

const TvLoginPage = AsyncComponent(() =>
    import("../Pages/Auth/tv-login/TvLoginPage").then((module) => module.default)
);

const TvLoginPageConfirmation = AsyncComponent(() =>
    import("../Pages/Auth/tv-login/TvLoginConfirmation").then((module) => module.default)
);

const AuthPage = AsyncComponent(() =>
    import("../Pages/Auth/oAuth/OAuthPage").then((module) => module.default)
);

function PageRoutes() {
    const { pathname, search } = useLocation();
    const history = useHistory();

    // const dispatch = useDispatch();
    const language = useSelector((state) => state.GeneralReducer.siteLanguage);
    const hideLayout = useSelector((state) => state.GeneralReducer.hideHeaderFooter);
    const authCheck = useSelector((state) => state.UserAuthReducer);

    /* ------------------------------ Side Effects ------------------------------ */
    useEffect(() => {
        localStorage.setItem("latestPath", pathname + search);
        //eslint-disable-next-line
    }, [pathname, search]);

    useEffect(() => {
        if (!authCheck.siteConfiguration?.forceToLogin) {
            const latestPath = localStorage.getItem("latestPath");
            if (authCheck.isLoggedIn && !!authCheck.deviceMustRemove) {
                history.push(RoutesHelper("DeviceManagementURL"));
            }
            // else if (latestPath) {
            //     history.push(latestPath);
            // }
            // else {
            //     history.push(RoutesHelper("HomeURL"));
            // }
        } else {
            if (!authCheck.isLoggedIn) {
                history.push(RoutesHelper("LoginURL"));
            } else if (authCheck.needToSelectProfile) {
                history.push(RoutesHelper("RegisterURL"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCheck.siteConfiguration, authCheck.isLoggedIn]);

    return (
        <React.Fragment>
            {!hideLayout && <Header />}
            <div
                className={`d-flex flex-column flex-grow-1 ${
                    language.dir === "ltr" ? "ltr-mode" : "rtl-mode"
                } pb-sm-none pb-nav`}
            >
                <CommonModals />
                <Switch>
                    <Route path={RoutesHelper("HomeURL")} exact component={Home} />
                    <Route
                        path={`${RoutesHelper("OAuth")}/:serviceName`}
                        exact
                        component={AuthPage}
                    />
                    <Route path={`${RoutesHelper("LoginURL")}`} exact component={Auth} />
                    <Route path={`${RoutesHelper("RegisterURL")}`} exact component={NewProfile} />
                    <Route needAuth path={RoutesHelper("TvLogin")} exact component={TvLoginPage} />
                    <Route
                        path={`${RoutesHelper("DeviceManagementURL")}`}
                        exact
                        component={DeviceManagement}
                    />
                    <Route
                        path={`${RoutesHelper("ProfileURL")}/:section`}
                        exact
                        component={ProfilePage}
                    />
                    <Route
                        path={`${RoutesHelper("SingleMovie")}/:permalink`}
                        exact
                        component={SingleMovie}
                    />
                    <Route
                        path={`${RoutesHelper("CastAndCrew")}/:permalink`}
                        exact
                        component={CastAndCrew}
                    />
                    <Route
                        path={`${RoutesHelper("Player")}/:playPermaLink`}
                        exact
                        component={Player}
                    />
                    <Route
                        path={`${RoutesHelper("General")}/:permalink`}
                        exact
                        component={GeneralPages}
                    />
                    <Route
                        path={`${RoutesHelper("SearchURL")}`}
                        exact={true}
                        component={SearchPage}
                    />
                    <Route
                        path={`${RoutesHelper("CategoryURL")}/:categoryId`}
                        exact={false}
                        component={CategoryPage}
                    />
                    <Route
                        path={`${RoutesHelper("PaymentURL")}/:token`}
                        exact
                        component={PaymentPage}
                    />
                    <Route
                        needAuth={true}
                        path={RoutesHelper("TvLoginConfirmation")}
                        exact
                        component={TvLoginPageConfirmation}
                    />
                    <Route
                        needAuth={true}
                        path={`${RoutesHelper("SubscriptionURL")}`}
                        exact
                        component={SubscriptionPage}
                    />
                    <Route
                        path={`${RoutesHelper("ActivityURL")}/:activityName`}
                        exact
                        component={ActivityPage}
                    />
                    <Route path={"/*"} component={NotFound} />
                </Switch>
            </div>
            {pathname !== RoutesHelper("DeviceManagementURL") &&
                pathname !== RoutesHelper("RegisterURL") &&
                !pathname.includes("/v/") && // when user is in the Player Route
                pathname !== RoutesHelper("LoginURL") && (
                    <div className="d-lg-none d-block">
                        <AppBar />
                    </div>
                )}
            {!hideLayout && <Footer />}
        </React.Fragment>
    );
}

export default PageRoutes;
