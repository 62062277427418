import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { t } from "i18next";

function Footer() {
  // const FooterStatus = useSelector(state => state.GeneralReducer.hideFooter);

  let menuItems = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Contact Us"),
      url: "/",
    },
    {
      title: t("Help Center"),
      url: "/",
    },
    {
      title: t("Applications"),
      url: "/",
    },
    {
      title: t("Latest News"),
      url: "/",
    },
    {
      title: t("Terms and Conditions"),
      url: "/",
    },
    {
      title: t("Privacy policy"),
      url: "/",
    },
  ];
  let socialItems = [
    {
      title: t("twitter"),
      url: "/",
    },
    {
      title: t("facebook"),
      url: "/",
    },
    {
      title: t("youtube"),
      url: "/",
    },
    {
      title: t("instagram"),
      url: "/",
    },
    {
      title: t("telegram"),
      url: "/",
    },
  ];

  return (
    <footer className="d-md-block d-none">
      <div className="container" id="footer">
        <div className="row">
          <div className="col-12 text-center">
            <ul className="footer-menu">
              {menuItems.map((item, index) => (
                <li className="ms-4" key={index}>
                  <HashLink smooth to={`${item.url}`} title={item.title}>
                    {item.title}
                  </HashLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="footer-social">
              {socialItems.map((item, index) => (
                <HashLink
                  key={index}
                  smooth
                  to={`${item.url}`}
                  title={item.title}
                >
                  <i className={`fa-brands fa-${item.title}`} />
                </HashLink>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <p className="footer-copyright">
              All rights reserved © 2022
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
