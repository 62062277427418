import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const CustomModal = (props) => {
    const {
        onClose,
        title = "",
        header,
        footer,
        show,
        closeButton = true,
        children,
        modalBodyClassName,
        ...rest
    } = props;
    return (
        <Modal show={show} onHide={onClose} {...rest}>
            {header && (
                <Modal.Header closeButton={closeButton}>
                    {header}
                    {title && <Modal.Title>{title}</Modal.Title>}
                </Modal.Header>
            )}
            <Modal.Body className={modalBodyClassName}>{children}</Modal.Body>
            {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </Modal>
    );
};

CustomModal.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    closeButton: PropTypes.bool,
    modalBodyClassName: PropTypes.string,
};

export default CustomModal;