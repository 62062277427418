import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ReactComponent as HomeLogo } from "../../assets/img/Home.svg";
import { ReactComponent as SearchLogo } from "../../assets/img/Search.svg";
import { ReactComponent as LiveTvLogo } from "../../assets/img/Play-2.svg";
import { ReactComponent as KidsLogo } from "../../assets/img/Kid.svg";
import RoutesHelper from "../../Routes/RoutesHelper";
import { useSelector } from "react-redux";

const AppBarButton = (props) => {
    const { label, icon, path, withImage } = props;

    return (
        <NavLink
            to={path}
            className="d-flex flex-column justify-content-center align-items-center mx-auto"
            //   activeClassName="selected-app-bar-button"
        >
            <span
                className={`${
                    withImage
                        ? "with-image-icon-holder"
                        : "icon-holder"
                }`}
            >
                {withImage ? (
                    <img src={icon} alt="app-bar-icon" />
                ) : (
                    icon
                )}
            </span>
            <span className="app-bar-label pt-2">{label}</span>
        </NavLink>
    );
};

AppBarButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.element,
    ]),
    path: PropTypes.string,
};

const AppBar = () => {
    const userProfileData = useSelector(
        (state) => state.UserAuthReducer.profile
    );
    const userIsLoggedIn = useSelector(
        (state) => state.UserAuthReducer.isLoggedIn
    );
    const appBarLinks = [
        {
            label: "Home",
            icon: <HomeLogo />,
            path: RoutesHelper("HomeURL"),
        },
        {
            label: "Search",
            icon: <SearchLogo />,
            path: RoutesHelper("SearchURL"),
        },
        {
            label: "Live TV",
            icon: <LiveTvLogo />,
            path: "#",
        },
        {
            label: "Kids",
            icon: <KidsLogo />,
            path: "#",
        },
    ];

    return (
        <div className="footer-bar d-flex justify-content-md-center position-fixed bottom-0 w-100 py-2">
            {appBarLinks.map((link, index) => (
                <AppBarButton
                    key={index}
                    icon={link.icon}
                    label={link.label}
                    path={link.path}
                />
            ))}
            <AppBarButton
                icon={
                    userProfileData?.image ??
                    "https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png"
                }
                label={userIsLoggedIn ? "Profile" : "Login"}
                path={
                    userIsLoggedIn
                        ? RoutesHelper("DashboardURL")
                        : RoutesHelper("LoginURL")
                }
                withImage
            />
        </div>
    );
};

export default AppBar;
