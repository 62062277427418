import { store } from "../Redux/store";
import { checkIsLoggedInFalse } from "../Redux/UserAuth/Actions";
import { toast } from "react-toastify";

import VodApi from "./VodApi";
import { t } from "i18next";

const getAccessToken = () => {
    return localStorage.getItem("sid");
};

const setAccessToken = (token) => {
    return localStorage.setItem("sid", token);
};

const deleteAccessToken = () => {
    store.dispatch(checkIsLoggedInFalse());
    return localStorage.removeItem("sid");
};

const isLoggedIn = () => {
    return new Promise((resolve, reject) => {
        VodApi("get", "/auth/isLoggedIn")
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const checkEmailHasPassword = (email) => {
    return new Promise((resolve, reject) => {
        VodApi("post", "/auth/email", { email })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const requestToSendOrResendOTP = (email) => {
    return new Promise((resolve, reject) => {
        VodApi("post", "/auth/otpSend", { email })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const loginWithPassword = (email, password) => {
    return new Promise((resolve, reject) => {
        VodApi("post", "/auth/password", { email, password })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const LoginOtp = (email, code) => {
    return new Promise((resolve, reject) => {
        VodApi("post", "/auth/otp", { email, code })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const logout = () => {
    return new Promise((resolve, reject) => {
        VodApi("get", "/auth/logout")
            .then((res) => {
                resolve(res.data);
                deleteAccessToken();
                window.location.reload();
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const requestSignup = (username) => {
    return new Promise((resolve, reject) => {
        VodApi("post", "/auth/register", { username: username })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const setNewPassordForUserAccount = (password, token) => {
    return new Promise((resolve, reject) => {
        VodApi("put", "/auth/setPassword", {
            password,
            token,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const get_configuration = (language = "en") => {
    return new Promise((resolve, reject) => {
        VodApi("get", `/metadata/basicConfiguration`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const checkValidateTvCode = (code) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await VodApi("post", `/auth/tv/validateCode`, { code });

            if (res.status) {
                await resolve(res.data);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export {
    get_configuration,
    getAccessToken,
    setAccessToken,
    deleteAccessToken,
    isLoggedIn,
    checkEmailHasPassword,
    requestToSendOrResendOTP,
    loginWithPassword,
    LoginOtp,
    logout,
    requestSignup,
    setNewPassordForUserAccount,
    checkValidateTvCode,
};
