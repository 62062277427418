import imagePlaceholder from '../assets/img/photo-placeholder.png'

const onImageBroken = (elementRef) => { 
    elementRef.current.src = imagePlaceholder
 }

const objectHelpers = {
    addPropertyToObject: (mainObject = {}, objectPropertyName = "", objectValue) => {
        mainObject[objectPropertyName] = objectValue;
    },
    removePropertyFromObject: (mainObject, propertyName) => {
        delete mainObject[propertyName];
    },
};

const detectLangCodeFromURL = (languages = []) => {
    let prefix = "/";
    let path = window.location.pathname.split("/");
    const firstItemOfPath = path[1];

    const selectedLanguageInURL = languages.find((item) => item.code === firstItemOfPath);

    // return { prefix, path, selectedLanguageInURL };
    if (selectedLanguageInURL) {
        prefix = `/${firstItemOfPath}/`;
        return {
            prefix,
            langCode: firstItemOfPath,
            path,
            selectedLanguageInURL,
        };
    } else {
        return {
            prefix,
            path: path.join("/"),
        };
    }
};

const formattingTimeToMinAndSec = (timeToSeconds) => {
    let minutes = parseInt((timeToSeconds / 60) % 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let seconds = parseInt(timeToSeconds % 60);
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [minutes, seconds].join(":");
};

export { objectHelpers, detectLangCodeFromURL, formattingTimeToMinAndSec, onImageBroken };
